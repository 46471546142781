import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Asset } from '../../../types/asset';

const Logo = styled.img`
  width: 100%;
  max-width: 568px;
`;

const Container = styled.div<{backgroundColor?: string, backgroundImage: string}>(({backgroundColor, backgroundImage}) => `
  ${backgroundColor && `
    background-color: ${backgroundColor};
  `}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-blend-mode: multiply;
  background-image: url(${backgroundImage});
  @media (max-width: 775px) {
    background-size: cover;
  }
`);

interface Block {
    headline: string;
    text: string;
}

export interface HeaderProps {
    backgroundImage: Asset;
    backgroundColour: string;
    logo: Asset;
    blocks: Block[];
}

export const Header: React.FC<HeaderProps> = ({ logo, backgroundColour, backgroundImage , blocks }) => {
  return (
    <Container
        backgroundColor={backgroundColour}
        backgroundImage={backgroundImage.url}
    >
        <div className="max-w-layout mx-auto py-6 sm:py-16 px-6 sm:px-0">
            <Logo className="mx-auto mb-12" src={logo.url} alt={logo.name} />
            <div className="flex flex-wrap -mx-5">
                {blocks.map((block, index) => (
                    <div key={index} className="px-6 w-full md:w-1/2 h-auto mb-10">
                        <div className="bg-black h-full p-12 text-center flex flex-col justify-center">
                            <div>
                                <div className="font-new-grotesk-square text-5xl sm:text-6xl leading-tightest text-brand-blue uppercase">{block.headline}</div>
                                <div className="text-white mt-6">{block.text}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="mb-0 sm:mb-8 mx-auto text-center py-4 sm:py-10">
                <div title="Add to Calendar" className="addeventatc">
                    <div className="text-black relative" style={{ top: -1 }}>Add to Calendar</div>
                    <span className="start">16-11-2020 14:00</span>
                    <span className="end">16-11-2020 18:00</span>
                    <span className="timezone">Europe/London</span>
                    <span className="title">We Have A Climate Crisis – ALT COP Live</span>
                    <span className="description">Join us live from 2:00pm on Monday 16th November with guest speakers from BrewDog, Patagonia, LUSH, Vice, Iceland, The Plastic Planet and more. We have a climate crisis and governments aren't acting quick enough. It's time to fix our future.</span>
                    <span className="location">https://www.youtube.com/watch?v=QKWTNW_uHRg&feature=youtu.be</span>
                </div>
            </div>
        </div>
    </Container>
  );
};
