import { Header } from './header';
import { Roundel } from './roundel';
import { Hero } from './hero';
import { SpeakerCollection } from './speakerCollection';
import { TextBanner } from './textBanner';
import { Footer } from './footer';
import { ContentBlock } from './contentBlock';
import { Schedule } from './schedule';
import { YoutubeEmbed } from './youtubeEmbed';

export const components: { [index: string]: any } = {
  header: Header,
  roundel: Roundel,
  hero: Hero,
  speakerCollection: SpeakerCollection,
  textBanner: TextBanner,
  footer: Footer,
  contentBlock: ContentBlock,
  schedule: Schedule,
  youtubeEmbed: YoutubeEmbed,
  default: null,
};
