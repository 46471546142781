import React, { Fragment } from 'react';
import styled from 'styled-components';

const RoundelContainer = styled.div<{backgroundColor?: string}>(({backgroundColor}) => `
  ${backgroundColor && `
    background-color: ${backgroundColor};
  `}
`);

const TextContainer = styled.div`
    transform: rotate(-15deg);
`;

export interface RoundelProps {
    backgroundColour: string;
    title: string;
}

export const Roundel: React.FC<RoundelProps> = ({ title, backgroundColour}) => {
    return (
        <div className="max-w-6xl mx-auto -m-12 px-6 ld:px-0">
            <RoundelContainer
                backgroundColor={backgroundColour}
                className="flex uppercase w-24 h-24 rounded-full"
            >
            <TextContainer className="-ml-1 font-new-grotesk-square p-2 self-center text-center leading-none text-black text-3xl">
                {title}
            </TextContainer>
            </RoundelContainer>
        </div>
    );
};
