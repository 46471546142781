import React, { Fragment } from 'react';

export interface YoutubeEmbedProps {
    youtubeUrl: string;
}

export const YoutubeEmbed: React.FC<YoutubeEmbedProps> = ({ youtubeUrl }) => {
    return (
        <div className="w--full mx-auto px-8 md:px-0 py-8">
            <div className="max-w-4xl mx-auto">
                <div className="embed-container">
                    <iframe src={youtubeUrl} frameBorder={0} allowFullScreen={true}></iframe>
                </div>
            </div>
        </div>
    );
};
