import React, { Fragment } from 'react';
import styled from 'styled-components';

const Container = styled.div<{backgroundColor?: string}>(({backgroundColor}) => `
  ${backgroundColor && `
    background-color: ${backgroundColor};
  `}
`);

export interface FooterProps {
  backgroundColour: string;
  address: string;
  followLabel: string;
  facebookUrl: string;
  twitterUrl: string;
  instagramUrl: string;
}

export const Footer: React.FC<FooterProps> = ({ backgroundColour, address, followLabel, facebookUrl, twitterUrl, instagramUrl}) => {
  return (
      <Container
          backgroundColor={backgroundColour}
      >
        <div className="w-full max-w-6xl mx-auto py-6 flex flex-wrap justify-between text-md text-gray px-6 lg:px-0 text-center md:text-left">
          <div className="w-full md:w-1/2 mb-5 md:mb-0">
            {address}
          </div>
          <div className="w-full md:w-1/2">
            <div className="flex justify-center md:justify-end">
              <div className="pr-3">{followLabel}</div>
              <div className="flex">
                <a className="pr-4" href={facebookUrl} target="_blank">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 8H6V12H9V24H14V12H17.642L18 8H14V6.333C14 5.378 14.192 5 15.115 5H18V0H14.192C10.596 0 9 1.583 9 4.615V8Z" fill="#CCCCCC"/>
                  </svg>
                </a>
                <a className="pr-4" href={instagramUrl} target="_blank">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2.163C15.204 2.163 15.584 2.175 16.85 2.233C20.102 2.381 21.621 3.924 21.769 7.152C21.827 8.417 21.838 8.797 21.838 12.001C21.838 15.206 21.826 15.585 21.769 16.85C21.62 20.075 20.105 21.621 16.85 21.769C15.584 21.827 15.206 21.839 12 21.839C8.796 21.839 8.416 21.827 7.151 21.769C3.891 21.62 2.38 20.07 2.232 16.849C2.174 15.584 2.162 15.205 2.162 12C2.162 8.796 2.175 8.417 2.232 7.151C2.381 3.924 3.896 2.38 7.151 2.232C8.417 2.175 8.796 2.163 12 2.163ZM12 0C8.741 0 8.333 0.014 7.053 0.072C2.695 0.272 0.273 2.69 0.073 7.052C0.014 8.333 0 8.741 0 12C0 15.259 0.014 15.668 0.072 16.948C0.272 21.306 2.69 23.728 7.052 23.928C8.333 23.986 8.741 24 12 24C15.259 24 15.668 23.986 16.948 23.928C21.302 23.728 23.73 21.31 23.927 16.948C23.986 15.668 24 15.259 24 12C24 8.741 23.986 8.333 23.928 7.053C23.732 2.699 21.311 0.273 16.949 0.073C15.668 0.014 15.259 0 12 0V0ZM12 5.838C8.597 5.838 5.838 8.597 5.838 12C5.838 15.403 8.597 18.163 12 18.163C15.403 18.163 18.162 15.404 18.162 12C18.162 8.597 15.403 5.838 12 5.838ZM12 16C9.791 16 8 14.21 8 12C8 9.791 9.791 8 12 8C14.209 8 16 9.791 16 12C16 14.21 14.209 16 12 16ZM18.406 4.155C17.61 4.155 16.965 4.8 16.965 5.595C16.965 6.39 17.61 7.035 18.406 7.035C19.201 7.035 19.845 6.39 19.845 5.595C19.845 4.8 19.201 4.155 18.406 4.155Z" fill="#CCCCCC"/>
                  </svg>
                </a>
                <a className="" href={twitterUrl} target="_blank">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M24 4.309C23.117 4.701 22.168 4.965 21.172 5.084C22.189 4.475 22.97 3.51 23.337 2.36C22.386 2.924 21.332 3.334 20.21 3.555C19.313 2.598 18.032 2 16.616 2C13.437 2 11.101 4.966 11.819 8.045C7.728 7.84 4.1 5.88 1.671 2.901C0.381 5.114 1.002 8.009 3.194 9.475C2.388 9.449 1.628 9.228 0.965 8.859C0.911 11.14 2.546 13.274 4.914 13.749C4.221 13.937 3.462 13.981 2.69 13.833C3.316 15.789 5.134 17.212 7.29 17.252C5.22 18.875 2.612 19.6 0 19.292C2.179 20.689 4.768 21.504 7.548 21.504C16.69 21.504 21.855 13.783 21.543 6.858C22.505 6.163 23.34 5.296 24 4.309Z" fill="#CCCCCC"/>
                </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Container>
  );
};
