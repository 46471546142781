import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Asset } from '../../../types/asset';

const HeroContainer = styled.div<{ backgroundImage: string }>(({ backgroundImage }) => `
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-blend-mode: multiply;
  background-image: url(${backgroundImage});
`);

interface HeroProps {
  headline: string;
  bodyText: string;
  ctaLabel: string;
  ctaLink: string;
  youtubeUrl: string;
  backgroundImage: Asset;
}

export const Hero: React.FC<HeroProps> = ({
    headline,
    backgroundImage,
    bodyText,
    ctaLabel,
    ctaLink,
    youtubeUrl,
}) => {
  return (
    <HeroContainer backgroundImage={backgroundImage.url} className="max-w-6xl mx-auto text-center mt-20 pb-20 px-6 md:px-0">
      <div className="mb-5 uppercase text-5xl md:text-6xl leading-none font-new-grotesk-square leading-tightest">{headline}</div>
      <div className="mb-8 max-w-2xl mx-auto richtext" dangerouslySetInnerHTML={{__html: bodyText}} />
      <div className="mb-8">
          <div title="Add to Calendar" className="addeventatc">
              <div className="text-white">Add to Calendar</div>
              <span className="start">16-11-2020 15:00</span>
              <span className="end">16-11-2020 18:00</span>
              <span className="timezone">Europe/London</span>
              <span className="title">We Have A Climate Crisis – ALT COP Live</span>
              <span className="description">Join us live from 3:00pm on Monday 16th November with guest speakers from BrewDog, Patagonia, LUSH, Vice, Iceland, The Plastic Planet and more. We have a climate crisis and governments aren't acting quick enough. It's time to fix our future.</span>
              <span className="location">https://www.youtube.com/watch?v=QKWTNW_uHRg&feature=youtu.be</span>
          </div>
      </div>
      {/*<div className="mb-12">*/}
      {/*  <a href={ctaLink} target="_blank">*/}
      {/*    <button className="w-full max-w-sm inline-flex uppercase py-4 text-white items-center align-middle justify-center bg-black transition-03 cursor-pointer border-0 outline-none tracking-none">*/}
      {/*        {ctaLabel}*/}
      {/*    </button>*/}
      {/*  </a>*/}
      {/*</div>*/}
        <div className="max-w-4xl mx-auto">
            <div className="embed-container">
                <iframe src={youtubeUrl} frameBorder={0} allowFullScreen={true}></iframe>
            </div>
        </div>
    </HeroContainer>
  );
};
