import React, { Fragment } from 'react';
import styled from 'styled-components';

const Container = styled.div<{backgroundColour?: string}>(({backgroundColour}) => `
  ${backgroundColour && `
    background-color: ${backgroundColour};
  `}
`);

const ListContainer = styled.ul`
  column-count: 2;
  column-gap: 100px;
  @media (max-width: 1024px) {
    column-count: 1;
  }
`;

interface Item {
    headline: string;
    subtext: string;
}

interface ContentBlockProps {
    headline: string;
    subtitle: string;
    intro: string;
    backgroundColour: string;
    items: Item[];
}

export const Schedule: React.FC<ContentBlockProps> = ({ headline, subtitle, items, backgroundColour, intro, schedule }) => {
    return (
        <Container backgroundColour={backgroundColour} className="w-full py-16 md:py-20">
            <div className="max-w-6xl mx-auto text-center px-6 md:px-0">
                <div className="max-w-2xl mx-auto">
                    <div className="uppercase text-6xl font-new-grotesk-square leading-tightest text-white">{headline}</div>
                    <div className="mt-16 uppercase text-6xl font-new-grotesk-square leading-tightest text-brand-blue">{subtitle}</div>
                </div>
                <div>
                    <div className="mt-10 max-w-3xl mx-auto text-white">
                        {intro}
                    </div>
                </div>
                <ListContainer className="max-w-3xl mx-auto mt-10 py-3" style={{ breakInside: 'avoid-column', pageBreakInside: 'avoid' }}>
                    {items.map((item, index) => (
                        <li key={index} className="mb-6">
                            <div>
                                <div className="font-new-grotesk-square text-brand-blue leading-tightest uppercase text-3xl">{item.headline}</div>
                                <div className="text-brand-gray">{item.subtext}</div>
                            </div>
                        </li>
                    ))}
                </ListContainer>
            </div>
        </Container>
    );
};
