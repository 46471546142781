import React, { Fragment } from 'react';
import styled from 'styled-components';

const Container = styled.div<{backgroundColor?: string}>(({backgroundColor}) => `
  ${backgroundColor && `
    background-color: ${backgroundColor};
  `}
`);

const Text = styled.div<{textColour?: string}>(({textColour}) => `
  ${textColour && `
    color: ${textColour};
  `}
`);

export interface TextBannerProps {
    textColour: string;
    backgroundColour: string;
    headline: string;
}

export const TextBanner: React.FC<TextBannerProps> = ({ headline, textColour, backgroundColour}) => {
    return (
        <Container backgroundColor={backgroundColour}>
            <div className="max-w-6xl mx-auto py-16 px-6 md:px-0">
                <Text className="text-5xl md:text-6xl leading-tightest uppercase text-center font-new-grotesk-square" textColour={textColour}>{headline}</Text>
            </div>
        </Container>
    );
};
