import React from 'react';
import {components} from './config';

const componentMapper = (type: string) => components[type] || components.default;

interface SectionProps {
  sectionType: string;
  sectionData: any;
}

export const Section: React.FC<SectionProps> = ({ sectionType, sectionData }) => {
  const Component = componentMapper(sectionType);

  if (Component) {
    return <Component {...sectionData} />;
  }

  return null;
};
