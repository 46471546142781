import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Asset } from '../../../types/asset';

const Container = styled.div<{ backgroundImage: string }>(({ backgroundImage }) => `
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-blend-mode: multiply;
  background-image: url(${backgroundImage});
`);

interface ContentBlockProps {
    heroImage: Asset;
    secondaryImage: Asset;
    backgroundImage: Asset;
    headline: string;
    content: string;
}

export const ContentBlock: React.FC<ContentBlockProps> = ({ heroImage, secondaryImage, backgroundImage, headline, content }) => {
    return (
        <Container backgroundImage={backgroundImage.url} className="max-w-6xl mx-auto text-center mt-20 pb-20 px-6 md:px-0">
            <div className="w-full max-w-xl mx-auto">
                <img className="text-center" src={heroImage.url} alt={heroImage.name} />
            </div>
            <div className="max-w-2xl mx-auto">
                <div className="mt-5 uppercase text-5xl font-new-grotesk-square leading-tightest">{headline}</div>
            </div>
            <div className="my-20 max-w-2xl mx-auto" dangerouslySetInnerHTML={{__html: content}} />
            {secondaryImage &&
                // tslint:disable-next-line:jsx-wrap-multiline
                <div className="w-full max-w-xl mx-auto">
                    <img className="text-center" src={secondaryImage.url} alt={secondaryImage.name} />
                </div>
            }
        </Container>
    );
};
