import React from 'react';
import { Section } from '../components/sections';
import { Layout } from '../components/layout';

interface PageProps {
  pageContext: {
    title: string;
    slug: string;
    description: string;
    sections: any[];
  };
}

export default ({ pageContext }: PageProps) => {
  const { title, description, sections } = pageContext;
  return (
    <Layout title={title} description={description}>
      <div>
        {sections && sections.map((section, sectionIndex) => {
          return (
            <Section key={sectionIndex} sectionData={section.sectionData} sectionType={section.contentType} />
          );
        })}
      </div>
    </Layout>
  );
};
