import React from 'react';
import '../styles/global.css';
import SEO from './seo';

interface LayoutProps {
  title: string;
  description: string;
}

export const Layout: React.FC<LayoutProps> = ({ children, title, description }) => {
  return (
    <>
      <SEO title={title} description={description} />
      <div className="w-full text-sm sm:text-base py-4 text-center text-brand-dark-gray" style={{backgroundColor: '#F2F2F2'}}>
          <div className="flex justify-center">
              <div className="hidden md:block self-center">This event is powered by</div>
              <div className="block md:hidden self-center">Powered by</div>
              <img className="self-center px-3 bd-header-logo" src="/images/brewdog.png" alt="BrewDog logo" />
              <div className="self-center">and</div>
              <img className="self-center px-3 hf-header-logo" src="/images/hunter-foundation.png" alt="Hunter Foundation logo" />
          </div>
      </div>
      {children}
    </>
  );
};
