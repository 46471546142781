import React from 'react';
import { Asset } from '../../../types/asset';
import styled from 'styled-components';

const Container = styled.div<{backgroundColor?: string}>(({backgroundColor}) => `
  ${backgroundColor && `
    background-color: ${backgroundColor};
  `}
`);

interface Speaker {
    name: string;
    avatar: Asset;
    content: string;
}

interface SpeakerCollectionProps {
    headline: string;
    introText: string;
    backgroundColour?: string;
    speakers: Speaker[];
}

export const SpeakerCollection: React.FC<SpeakerCollectionProps> = ({ headline, introText, backgroundColour, speakers}) => {
    return (
        <Container backgroundColor={backgroundColour}>
            <div className="max-w-layout mx-auto pb-10 px-6 lg:px-0">
                <div className="pt-20 pb-16 uppercase text-5xl md:text-6xl text-center font-new-grotesk-square leading-tightest">{headline}</div>
                <div className="flex flex-wrap -mx-2 justify-center">
                    {speakers.map((speaker, index) => (
                        <div key={index} className="mb-6 sm:mb-12 px-2 w-full sm:w-1/2 md:w-1/3">
                            <div className="flex text-center">
                                <div className="flex flex-col">
                                    <div className="mx-auto w-24 h-24 md:w-xs md:h-xs rounded-full bg-gray-500">
                                        <img className="rounded-full w-24 h-24 md:w-xs md:h-xs object-cover" src={speaker.avatar.url} alt={speaker.name} />
                                    </div>
                                    <div className="mt-5 self-center px-5">
                                        <div className="text-xl md:text-2xl mb-5">{speaker.name}</div>
                                        <div className="text-sm md:text-base">{speaker.content}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Container>
    );
};
